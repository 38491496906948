<template>
  <v-container>
    <v-row v-if="advanced">
      <v-col cols="12" md="6">
        <v-select
          @input="selectTimeTable"
          :value="timeTableType"
          :items="selectableTimeTables"
          item-text="name"
          item-value="id"
          label="時間割の種類"
          dense
          outlined
        ></v-select> </v-col
    ></v-row>
    <v-row v-if="timeTableType">
      <v-col cols="12" md="12">
        <h1>
          {{
            selectableTimeTables
              .filter(s => s.id == timeTableType)
              .map(s => s.name)[0]
          }}
          <span v-if="timeTableType === 'classTable'">（{{ className }}）</span>
          の時間割を表示中
        </h1>
      </v-col>
    </v-row>
    <v-slide-group
      class="d-flex justify-center py-4"
      show-arrows
      :center-active="true"
    >
      <v-slide-item v-for="weekday in weekdays" :key="weekday.id">
        <v-btn
          :class="getWeekdayBtnClass(weekday.id)"
          depressed
          rounded
          @click="selectWeekday(weekday.id)"
          :color="getWeekdayColor(weekday.id)"
          >{{ weekday.name }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>

    <v-row class="TimeTable" no-gutters>
      <v-col cols="1">
        <v-row v-for="time in times" :key="time">
          <v-col>
            <v-card class="mx-auto" elevation="2" :height="cardHeight()">
              <v-list-item three-line class="px-1">
                <v-list-item-content>
                  <v-list-item-title class="headline mb-2">
                    {{ time }}
                  </v-list-item-title>
                  <span class="px-0 mx-0">時限</span>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-for="weekday in weekdays"
        :key="weekday.id"
        :cols="getColWidth(weekday.id)"
        @click="selectWeekday(weekday.id)"
        v-show="selectedWeekday == weekday.id || showInActiveCol"
      >
        <v-row v-for="time in times" :key="time">
          <v-col
            ><Classroom
              :numberOf="getNumberOfClassrooms(weekday, time)"
              :classrooms="getClassroomObj(weekday, time)"
              view="register"
              :inActive="selectedWeekday != weekday.id"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-slide-group
      class="d-flex justify-center py-4"
      show-arrows
      :center-active="true"
    >
      <v-slide-item v-for="weekday in weekdays" :key="weekday.id">
        <v-btn
          :class="getWeekdayBtnClass(weekday.id)"
          depressed
          rounded
          @click="selectWeekday(weekday.id)"
          :color="getWeekdayColor(weekday.id)"
          >{{ weekday.name }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
    <v-row v-if="unTimeTableNumber > 0">
      <v-col>
        時間割外の登録済み科目: <span>{{ unTimeTableNumber }} 件</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xm="12"
        sm="6"
        md="4"
        v-for="(c, i) in unTimeTable()"
        :key="i"
      >
        <Classroom :numberOf="1" :classrooms="[c]" view="register" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Classroom from "../Classroom";

export default {
  name: "Table",
  props: ["classrooms", "advanced"],
  components: {
    Classroom
  },
  data: () => ({
    times: [1, 2, 3, 4, 5],
    weekdays: [
      { id: 1, name: "月曜" },
      { id: 2, name: "火曜" },
      { id: 3, name: "水曜" },
      { id: 4, name: "木曜" },
      { id: 5, name: "金曜" }
      //   { id: 0, name: "その他" }
    ],
    selectedWeekday: 1,
    selectableTimeTables: [
      { id: "enrolled", name: "登録済み" },
      { id: "classTable", name: "クラス別" },
      { id: "all", name: "すべての授業" }
    ]
  }),
  mounted: function() {
    const date = new Date();
    const dayOfWeek = date.getDay();
    if ((dayOfWeek !== 0) & (dayOfWeek !== 6)) {
      this.selectedWeekday = dayOfWeek;
    }
  },
  computed: {
    timeTableType() {
      const userType = this.$store.state.userType;
      if (userType === "coordinator" || userType === "teacher") {
        return "teach";
      } else {
        return "enrolled";
      }
    },
    showInActiveCol() {
      if (this.$vuetify.breakpoint.xs) {
        return false;
      } else {
        return true;
      }
    },
    className() {
      return this.$store.state.loginUser.className;
    },
    targetClassrooms() {
      if (this.timeTableType == "enrolled") {
        const result = this.$store.state.classrooms.filter(c => {
          return this.$store.state.enrolledList.find(
            e =>
              e.courseCode == c.courseCode || e.courseCode == c.parentCourseCode
          );
        });
        return result;
      } else if (this.timeTableType == "teach") {
        const result = this.$store.state.classrooms.filter(c => {
          return this.$store.state.teachCourseList.find(
            t =>
              t.courseCode == c.courseCode || t.courseCode == c.parentCourseCode
          );
        });
        return result;
      } else if (this.timeTableType == "classTable") {
        const result = this.$store.state.classrooms.filter(
          c => c.className == this.className
        );
        return result;
      } else if (this.timeTableType == "all") {
        return this.$store.state.classrooms;
      } else {
        return null;
      }
    },
    unTimeTableNumber() {
      return this.unTimeTable().length;
    }
  },
  methods: {
    cardHeight() {
      return this.$store.state.cardHeight;
    },
    selectWeekday(weekday) {
      this.selectedWeekday = weekday;
    },
    dayAndTime(weekday, time) {
      return weekday.id.toString() + time.toString();
    },
    getEnrolled() {
      return this.$store.state.enrolledList;
    },
    getNumberOfClassrooms(weekday, time) {
      const dayTime = this.dayAndTime(weekday, time);
      const classrooms = this.targetClassrooms.filter(e => {
        // return e.showOrder === parseInt(dayTime);
        return e.showOrderList.includes(parseInt(dayTime));
      });
      return classrooms.length;
    },
    getClassroomObj(weekday, time) {
      const dayTime = this.dayAndTime(weekday, time);
      const classrooms = this.targetClassrooms.filter(e => {
        // return e.showOrder === parseInt(dayTime);
        return e.showOrderList.includes(parseInt(dayTime));
      });
      if (classrooms.length > 0) {
        return classrooms;
      } else {
        return null;
      }
    },
    selectTimeTable(acction) {
      this.timeTableType = acction;
    },
    getColWidth(weekday) {
      let activeWidth = 5;
      let nearActiveWidth = 2;
      let nonActiveWidth = 1;
      if (this.selectedWeekday == 1 || this.selectedWeekday == 5) {
        activeWidth += 1;
      }
      if (this.$vuetify.breakpoint.xs) {
        activeWidth = 11;
        nearActiveWidth = 0;
        nonActiveWidth = 0;
      }
      if (this.selectedWeekday == weekday) {
        return activeWidth;
      } else if (
        this.selectedWeekday == weekday - 1 ||
        this.selectedWeekday == weekday + 1
      ) {
        return nearActiveWidth;
      } else {
        return nonActiveWidth;
      }
    },
    getWeekdayColor(weekdayId) {
      if (this.selectedWeekday == weekdayId) {
        if (weekdayId == 1) {
          return "#779438";
        } else if (weekdayId == 2) {
          return "#007d7a";
        } else if (weekdayId == 3) {
          return "#055a8e";
        } else if (weekdayId == 4) {
          return "#772f6d";
        } else if (weekdayId == 5) {
          return "#a7374a";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getWeekdayBtnClass(weekdayId) {
      if (this.selectedWeekday == weekdayId) {
        return "mx-0 weekdayText";
      } else {
        return "mx-0";
      }
    },
    unTimeTable() {
      const unDay = "6";
      const unTime = "6";
      const classrooms = this.targetClassrooms.filter(e => {
        // return e.showOrder === parseInt(dayTime);
        const b = e.schedules.filter(s => {
          return !!(s.weekday === unDay || s.period === unTime);
        });
        return b.length > 0;
      });
      // console.log(classrooms);
      return classrooms;
    }
  }
};
</script>
<style scoped>
.weekdayText {
  color: #fff;
}

.weekday1 {
  background-color: #779438;
  color: #fff;
}
.weekday2 {
  background-color: #007d7a;
  color: #fff;
}
.weekday3 {
  background-color: #055a8e;
  color: #fff;
}
.weekday4 {
  background-color: #772f6d;
  color: #fff;
}
.weekday5 {
  background-color: #a7374a;
  color: #fff;
}
</style>
