<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <Information />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <Table />
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="12" md="12">
        <Additional />
      </v-col> -->
    </v-row>
  </v-container>
</template>
<script>
import Information from "../components/TimeTable/Information.vue";
import Table from "../components/TimeTable/Table.vue";
// import Additional from "../components/TimeTable/Additional.vue";

export default {
  name: "TimeTable",
  components: {
    Information,
    Table
    // Additional
  }
};
</script>
