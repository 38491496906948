<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mx-auto" elevation="8">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                このページの使い方
              </v-list-item-title>

              <v-list-item-subtitle>
                登録済のGoogle Classroom を確認できます。<br />
                <span v-if="canResearch"
                  >「授業満足度調査」は<router-link to="/timetable"
                    >時間割</router-link
                  >のページから行ってください。</span
                >
              </v-list-item-subtitle>
              <v-btn x-small rounded @click="toggleShow">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                詳細説明を{{ show ? "閉じる" : "開く" }}
              </v-btn>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>

                  <v-card-text>
                    自分が履修している（担当している）授業数と登録済件数が一致しているか確認してください。
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Information",
  data: () => ({}),
  computed: {
    show() {
      return this.$store.state.detailShow;
    },
    canResearch() {
      return this.$store.state.canResearch;
    }
  },
  methods: {
    toggleShow() {
      this.$store.commit("TOGGLE_DETAIL_SHOW");
    }
  }
};
</script>
<style scoped>
.info-messages li {
  line-height: 1.5rem;
}
.additional-message {
  padding: 1rem 0 0 0;
}

.counterMaru ol {
  list-style: none;
}
.counterMaru li {
  list-style: none;
}
</style>
