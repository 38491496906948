<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <Information />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <EnrolledList />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Information from "../components/Enrolled/Information.vue";
import EnrolledList from "../components/Enrolled/EnrolledList.vue";

export default {
  name: "Enrolled",
  components: {
    Information,
    EnrolledList
  }
};
</script>
