<template>
  <v-container>
    <v-row>
      <v-col>
        登録済み科目数: <span>{{ resultsNumber }} 件</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xm="12"
        sm="6"
        md="4"
        v-for="(c, i) in submitted"
        :key="i"
      >
        <Classroom :numberOf="1" :classrooms="[c]" view="register" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Classroom from "../Classroom";
export default {
  name: "EnrolledList",

  components: {
    Classroom
  },
  computed: {
    submitted() {
      const userType = this.$store.state.userType;
      const result = this.$store.state.classrooms.filter(c => {
        let target;
        if (userType == "coordinator" || userType == "teacher") {
          target = this.$store.state.teachCourseList;
        } else {
          target = this.$store.state.enrolledList;
        }
        return target.find(e => {
          return (
            e.courseCode == c.courseCode || e.courseCode == c.parentCourseCode
          );
        });
      });
      return result;
    },
    resultsNumber() {
      const userType = this.$store.state.userType;
      if (userType == "coordinator" || userType == "teacher") {
        return this.$store.state.teachCourseList.length;
      } else {
        return this.$store.state.enrolledList.length;
      }
      // return this.$store.state.enrolledList.length;
    }
  },
  methods: {}
};
</script>
